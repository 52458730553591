<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">{{ $t('gallery') }}</h1>

    <!-- LIGHTBOX -->
    <lightGallery
      :images="images"
      :index="index"
      :disable-scroll="true"
      @close="closeLightbox"
    />

    <!-- THUMBNAIL GALLERY -->
    <div class="thumbnail-gallery">
      <div
        v-for="(image, index) in images" :key="index"  
        class="thumbnail-gallery__image-wrapper"
      >
        <v-img
          class="thumbnail-gallery__thumbnail"
          :src="image.url"
          @click="setIndex(index)"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import { LightGallery } from 'vue-light-gallery';
import imagesData from '@/assets/data/images-data.json';

export default {
  name: 'Gallery',
  components: {
    lightGallery: LightGallery
  },
  metaInfo: {
    meta: [
      { name: 'description', content: 'Bilder Gallery der Band Rabengott.'},
      { name: 'robots', content: 'index, follow'}
    ]
  },
  data: () => {
    return {
      index: null,
      imagesPerRow: 3,
      imagesData: imagesData,
    }
  },
  computed: {
    images() {
      let result = imagesData.map((image) => {
        return {title: image['title' + this.$i18n.locale], url: require(`@/assets/pictures/gallery/${image.url}`)};
      })
      return result
    },
  },
  watch: {
    index: function() {
      // watch index to determine whether the lightbox is open or closed
      if(this.index !== null) {
        this.$store.commit('settings/set', {key: 'lightboxOpen', value: true});
      } else {
        this.$store.commit('settings/set', {key: 'lightboxOpen', value: false});
      }
    }
  },
  methods: {
    /** Close the Lightbox */
    closeLightbox() {
      this.index = null;
    },
    /** Set the gallery index to the selected image */
    setIndex(thumbIndex) {
      this.index = thumbIndex;
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  &__image-wrapper {
    margin: 5px;
  }

  &__thumbnail {
    background-size: contain;
    cursor: pointer;
    height: auto;
    width: 250px;
  }
}
</style>