<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">{{ $t('history') }}</h1>

    <!-- PHOTO -->
    <v-container>
      <v-row 
        class="default-bottom-margin"
        justify="center"
      >
        <img 
            class="band-image"
            src="@/assets/pictures/history/band.jpg"
        />
      </v-row>

      <!-- BIO -->
      <v-container class="default-bottom-margin">
        <p v-if="$i18n.locale === 'de'">
          Synthetic Sympathy ist das Electro-Gothic Projekt von Julian Scheer.<br>
          <br>
          Musikalisch bedient es sich verschiedenen Einflüssen der schwarzen Musikszene und präsentiert sowohl EBM-lastige Lieder mit treibendem Beat die zum Tanzen einladen, aber auch von Futurepop und Dark-Wave inspirierte und somit eher melodiöse, nachdenkliche Lieder.
          Wie es sich zur Szene gehört, wird auch nicht davor zurückgeschreckt aus bekannten Strukturen auszubrechen.<br>
          <br>
          Die musikalischen Anfänge gehen bis in die junge Kindheit zurück und äußerten sich in begeistertem "Rumklimpern" auf sämtlichen Keyboards, die gefunden werden konnten.
          Diese Begeisterung wurde daraufhin in mehrjährigen Keyboard- und anschließendem Klavieunterricht trainiert, ehe der Übergang in die elektronische Musikproduktion erfolgte.<br>
          <br>
          2021 folgte schließlich die formale Gründung des Projekts und steht somit jetzt am Anfang der nächsten Phase.<br>
          <br>
          Be prepared...
        </p>
        <p v-else>
          Synthetic Sympathy is the electro-gothic project of Julian Scheer.<br>
          <br>
          Musically it uses various influences of the dark music scene and presents both EBM-heavy songs with a driving beat that invite you to dance, but also tracks inspired by Futurepop and Dark-Wave and thus rather melodic, thoughtful songs.
          As it is typical for the scene, it is also not afraid to break out of known structures.<br>
          <br>
          The musical beginnings go back to young childhood and expressed themselves in enthusiastic hammering away on all keyboards that could be found.
          This enthusiasm was then trained in several years of keyboard and subsequent piano lessons, before the transition to electronic music production happened.<br>
          <br>
          Finally, in 2021, the project was formally established and is now at the beginning of the next phase.<br>
          <br>
          Be prepared...
        </p>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: 'History',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Geschichte des Musikpojekts Synthetic Sympathy.'},
      { name: 'robots', content: 'index, follow'}
    ]
  },
}
</script>

<style lang="scss" scoped>
.band-image {
    height: 300px;
    background-color: black;
}
</style>