<template>
  <v-container>
    <!-- HEADING -->
    <h1 class="default-bottom-margin">{{ $t('band') }}</h1>

    <!-- TABS -->
      <v-tabs
        background-color="transparent"
        centered
        dark
        v-model="currentTab"
      >
        <v-tab
          class="hover-glow tab-item"
          v-for="tab in tabs"
          :key="tab.id"
          v-ripple="{'class': 'green--text'}"
        >
          {{ tab.label }}
        </v-tab>
        <v-tabs-items
          class="mt-5"
          id="tabs-items"
          v-model="currentTab"
          dark
        >
          <!-- GALLERY -->
          <v-tab-item>
						<gallery />
          </v-tab-item>

          <!-- HISTORY -->
					<v-tab-item>
						<history />
					</v-tab-item>

          <!-- EQUIPMENT -->
					<v-tab-item>
						<equipment />
					</v-tab-item>
				</v-tabs-items>
			</v-tabs>
	</v-container>
</template>

<script>
import equipment from '@/views/Equipment.vue';
import history from '@/views/History.vue';
import gallery from '@/views/Gallery.vue';

export default {
	name: 'Band',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Informationen zur Band und Bildergallery.'},
      { name: 'robots', content: 'index, follow'}
    ]
  },
	components: {
    equipment,
		gallery,
		history,
	},
	data: function() {
		return {
      currentTab: 'gallery',
      tabs: [
        {'id': 'gallery', 'label': this.$i18n.t('gallery'),},
        {'id': 'history', 'label': this.$i18n.t('history'),},
        {'id': 'equipment', 'label': this.$i18n.t('equipment'),}
      ]
		}
	},
}
</script>

<style lang="scss" scoped>
#tabs-items {
  background-color: transparent;
}

.tab-item {
  margin-top: auto;
  margin-bottom: auto;
  height: 90%;
}
</style>