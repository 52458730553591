<template>
    <v-container>
      <!-- HEADING -->
      <h1 class="default-bottom-margin">Equipment *</h1>

      <!-- PHOTO -->
      <v-row 
        class="default-bottom-margin equipment-image-container"
      >
        <img 
            class="equipment-image"
            src="@/assets/pictures/equipment/equipment_1.jpg"
        />
        <img 
            class="equipment-image"
            src="@/assets/pictures/equipment/equipment_2.jpg"
        />
      </v-row>

      <!-- EQUIPMENT LIST -->
      <v-container v-for="(items, category) in equipmentData" :key="category">
        <h3>{{ category}}</h3>
        <ul v-for="(item, index) in items" :key="index">
            <li>{{ item }}</li>
        </ul>
      </v-container>

      <!-- FOOTNOTE -->
      <v-container>
        <p class="footnote">
            <span v-if="$i18n.locale === 'de'">* Unvollständige Liste der Hard- und Software, die am häufigsten verwendet wird. Darüber hinaus gibt es eine hier nicht aufgeführte Vielzahl an Plugins, die in kleinerem Umfang eingesetzt wird.<br>
            Die Liste soll keine Werbung darstellen, sondern hat lediglich Informationscharakter. Synthetic Sympathy steht in keiner Verbindung mit den Herstellern der Hard- und Software und wird für die Erwähnung in keiner Weise vergütet.</span>
            <span v-else>* Incomplete list of the most used hardware and software. Additionally, there is a variety of plugins that are not mentioned, but are still used in a smaller scope.<br>
            This list is not meant as advertisement, but is of purely informational nature. Synthetic Sympathy is in no way associated with the companies behind the hardware and sofware and is not compensated.</span>
        </p>
      </v-container>
    </v-container>
</template>

<script>
import equipmentData from '@/assets/data/equipment-data.json';

export default {
	name: 'Equipment',
  metaInfo: {
    meta: [
      { name: 'description', content: 'Ausstattung des Musikpojekts Synthetic Sympathy.'},
      { name: 'robots', content: 'index, follow'}
    ]
  },
  data: () => {
    return {
      equipmentData: equipmentData[0],
    }
  } 
}
</script>

<style lang="scss" scoped>
.footnote {
  font-size: 12px;
  margin: 0;
}

.equipment-image-container {
  justify-content: space-evenly;
}

.equipment-image {
  height: 300px;
  background-color: black;
  margin: 5px;
}
</style>